import React from 'react';

import * as styled from './404.styled';

import { ErrorWrapper } from './ErrorWrapper';

export const Content = () => (
  <ErrorWrapper title="404 ERROR.">
    <styled.Text>
      We can&apos;t find the page that you&apos;re looking for.
    </styled.Text>
  </ErrorWrapper>
);
