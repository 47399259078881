import styled from 'styled-components';

import { StyledButton, StyledLink } from '@/components/general';
import * as text from '@/components/general/TextStyles';

export const Button = styled(StyledButton)`
  width: 140px;
  height: 40px;
  margin-top: 40px;
`;
export const ButtonLink = styled(StyledLink)``;

export const Box = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  margin-top: -${(props) => props.theme.headerSize.heightDesktop}px;

  @media (max-width: ${(props) => props.theme.breakpoints.lg}px) {
    margin-top: -${(props) => props.theme.headerSize.heightMobile}px;
  }
`;

export const Data = styled.div`
  width: 100%;
  text-align: center;
  position: relative;
`;

interface IDataBelowTitle {
  titleHeight: number;
}

export const DataBelowTitle = styled.div<IDataBelowTitle>`
  position: absolute;
  top: ${(props: IDataBelowTitle) => props.titleHeight}px;
  left: 50%;
  transform: translate(-50%, 0);
  width: 100%;
`;

export const Image = styled.img`
  position: absolute;
  width: 196px;
  height: 149px;
  top: -216px;
  left: 50%;
  transform: translate(-50%, 0);
`;

export const Title = styled.h1`
  ${text.FS40}
`;

export const TextBox = styled.div`
  margin-top: 15px;
`;
