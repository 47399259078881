import React, { useRef, useState, useEffect } from 'react';

import image from '@/images/Errors/404.png';

import { Links } from '@/utils/constants';
import * as styled from './ErrorWrapper.styled';
import { Layout } from '../general';

interface IErrorWrapper {
  title: string;
}

export const ErrorWrapper: React.FC<IErrorWrapper> = ({ children, title }) => {
  const titleRef = useRef(null);

  const [titleHeight, setTitleHeight] = useState(undefined);

  useEffect(() => {
    if (titleRef && titleRef.current) {
      setTitleHeight(titleRef.current.offsetHeight);
    }
  }, [titleRef]);

  return (
    <Layout>
      <styled.Box>
        <styled.Data>
          <styled.Image src={image} />
          <styled.Title ref={titleRef}>{title}</styled.Title>
          <styled.DataBelowTitle titleHeight={titleHeight}>
            <styled.TextBox>{children}</styled.TextBox>
            <styled.Button $inverted fs={16}>
              <styled.ButtonLink to={Links.HOME}>
                Back to home
              </styled.ButtonLink>
            </styled.Button>
          </styled.DataBelowTitle>
        </styled.Data>
      </styled.Box>
    </Layout>
  );
};
